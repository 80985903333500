<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12 sm6 md3 xl4 v-for="(item, i) in product" :key="i" pa-4>
        <v-card tile>
          <v-layout wrap justify-center pb-2>
            <v-flex xs12>
              <v-img
                v-if="item.images[0]"
                :src="mediaURL + item.images[0]"
                height="200px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color=" #68D389"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img
                v-else
                src="../../../assets/images/no-image.png"
                height="200px"
              />
            </v-flex>

            <v-flex
              xs12
              md12
              lg12
              xl12
              :style="{ height: '0px' }"
              text-left
              pa-4
              pt-2
            >
              <router-link :to="'/product/' + item._id">
                <span style="font-family: poppinsBold">{{
                  item.name
                }}</span>
                <!-- <span class="caption">
                  {{ item.caption }}
                </span> -->
              </router-link>
            </v-flex>
            <v-flex xs12 md12 lg12 xl12 py-5>
              <v-layout wrap justify-center pt-4>
                <v-flex xs12 px-2 py-2>
                  <router-link :to="'/editProduct/' + item._id">
                    <v-btn
                      color="success"
                      small
                      block
                      style="font-family: poppinsmedium"
                      dark
                      >Edit Product</v-btn
                    >
                  </router-link>
                </v-flex>
                <v-flex xs12 px-2>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        dark
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete Product
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Product?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="!product" wrap justify-center>
      <v-flex align-self-center pt-5>
        <span class="emptyField"
          >No Products Available!!</span
        >
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
//   props: ["programs", "itemHeading"],
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      product:[],
      msg: null,
    };
  },
    beforeMount() {
      this.getData();
    },
  methods: {
        getData() {
          this.appLoading = true;
          axios({
            method: "GET",
            url: "/product/getlist",
          })
            .then((response) => {
              this.appLoading = false;
              this.product = response.data.data;
            })
            .catch((err) => {
              this.appLoading = false;
              this.ServerError = true;
              console.log(err);
            });
        },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/product/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>